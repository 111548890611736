import { useMutation, useQuery } from '@tanstack/react-query';
import type { CustomParamsSerializer, ParamsSerializerOptions } from 'axios';
import { useContext } from 'react';
import { ApiEndpoints, ApiMapTypes, ApiResponseError, request, RequestParams } from 'services/api';

import { AuthContext } from './api.context';

export const useAuthToken = () => {
  const { authToken } = useContext(AuthContext);
  return authToken;
};

type Options<Response, Result = Response> = {
  onSuccess?: (value: Result) => void;
  onError?: (error: ApiResponseError) => void;
  mapper?: (response: Response) => Result;
  enabled?: boolean;
  baseUrl?: string;
};

type DeleteOptions<Response, Endpoint extends ApiEndpoints, Result = Response> = Options<Response, Result> & {
  idAttr: keyof ApiMapTypes[Endpoint]['delete']['params'];
};

export const useGetApi = <Endpoint extends ApiEndpoints, R>(
  params: Omit<RequestParams<Endpoint, 'get', R>, 'authToken' | 'method'>,
  options?: Options<R>,
  paramsSerializer?: ParamsSerializerOptions | CustomParamsSerializer,
) => {
  const authToken = useAuthToken();

  return useQuery(
    // todo: clear cache on logout
    [params.endpoint, params.params, authToken],
    () => request({ ...params, method: 'get', authToken, baseUrl: options?.baseUrl, paramsSerializer }),
    {
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
      onError: options?.onError,
      select: options?.mapper,
    },
  );
};

export const usePostApi = <
  Endpoint extends ApiEndpoints,
  Response extends ApiMapTypes[Endpoint]['post']['response'],
  Result = Response,
>(
  endpoint: Endpoint,
  options?: Options<Response, Result>,
) => {
  const authToken = useAuthToken();
  const { mapper, ...restOptions } = options || { mapper: (val) => val };

  return useMutation<Result, ApiResponseError, ApiMapTypes[Endpoint]['post']['params']>(
    [endpoint, authToken, mapper],
    (params) =>
      request({
        method: 'post',
        endpoint,
        params,
        // eslint-disable-next-line
        /*@ts-ignore*/
        mapper,
        authToken,
      }),
    { ...restOptions },
  );
};

export const useGetFileApi = <
  Endpoint extends ApiEndpoints,
  Response extends ApiMapTypes[Endpoint]['post']['response'],
  Result = Response,
>(
  endpoint: Endpoint,
  options?: Options<Response, Result>,
  responseType?: any,
) => {
  const authToken = useAuthToken();
  const { mapper, ...restOptions } = options || { mapper: (val) => val };

  return useMutation<Result, ApiResponseError, ApiMapTypes[Endpoint]['get']['params']>(
    [endpoint, authToken, mapper],
    (params) =>
      request({
        method: 'get',
        endpoint,
        params,
        /*@ts-ignore*/
        mapper,
        authToken,
        responseType,
      }),
    { ...restOptions },
  );
};

export const useDeleteApi = <
  Endpoint extends ApiEndpoints,
  Response extends ApiMapTypes[Endpoint]['delete']['response'],
  Result = Response,
>(
  endpoint: Endpoint,
  options?: DeleteOptions<Response, Endpoint, Result>,
  responseType?: any,
) => {
  const authToken = useAuthToken();
  const { mapper, ...restOptions } = options || { mapper: (val) => val };

  return useMutation<Result, ApiResponseError, ApiMapTypes[Endpoint]['delete']['params']>(
    [endpoint, authToken, mapper],
    (params) =>
      request({
        method: 'delete',
        endpoint: ((options && params?.[options.idAttr]) !== null
          ? `${endpoint}/${params![options!.idAttr]}`
          : endpoint) as Endpoint,
        params,
        /*@ts-ignore*/
        mapper,
        authToken,
        responseType,
      }),
    { ...restOptions },
  );
};
